@tailwind base;
@tailwind components;
@tailwind utilities;

/* import a font locally as a font face from assets folder */
@font-face {
  font-family: "Biome";
  src: url("./assets/biomebold.ttf") format("truetype");
}
@font-face {
  font-family: "BiomeRegular";
  src: url("./assets/BiomeRegular.ttf") format("truetype");
}
@font-face {
  font-family: "BiomeSemibold";
  src: url("./assets/regular.ttf") format("truetype");
}
.biome-regular {
  font-family: "BiomeRegular", sans-serif;
}
.biome-semibold {
  font-family: "BiomeSemibold", sans-serif;
}
.biome-bold {
  font-family: "Biome", sans-serif;
}
.MuiTypography-body1 {
    font-family: "Biome", sans-serif !important;
    font-size:14px;
}
* {
  box-sizing: border-box;
}
html{
  scroll-behavior: smooth;
}
.grad-bg {
  background: linear-gradient(
    91.02deg,
    #fac744 8.95%,
    #8b14ef 58.82%,
    #3333ff 107.66%
  );
}
.grad-bg-text{
   background: linear-gradient(91.02deg, #FAC744 8.95%, #B257FF 58.82%, #344AFF 107.66%);
   color: white;
  font-family: "BiomeRegular", sans-serif;

}
.grad-token{
  border-radius: 617px;
background: rgba(154, 61, 255, 0.20);
filter: blur(70.15555572509766px);

}
.grad-step{
  border-radius: 10px;
background: rgba(250, 252, 255, 0.10);
backdrop-filter: blur(17.5px);
}
body{
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}
.sticky-nav {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 102;
  background-color: #010738;
  padding-right: 4rem;
  padding-left:  4rem;
  margin:0 auto;
}
.m-sticky-nav {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 102;
  background-color: #010738;
  padding: 1rem;
}
.bg-special {
  clip-path: polygon(
    0% 5%,
    4% 0%,
    96% 0%,
    100% 4%,
    100% 93%,
    96% 100%,
    34% 100%,
    20% 88%,
    3% 88%,
    0% 84%
  );
  width: 100%;
  height: 70vh;
}
.blur-circle {
  width: 372px;
  height: 372px;
  background: rgba(51, 51, 255, 0.3);
  filter: blur(80px);
  border-radius: 50%;
  position: absolute;
  top: -10em;
  left: -5em;
}
.blur-circle-purple {
  width: 322px;
  height: 322px;
  background: rgba(163, 51, 255, 0.3);
  filter: blur(80px);
  border-radius: 50%;
  position: absolute;
  bottom: -2em;
  left: 3rem;
}
.blur-circle-purple-r {
  width: 322px;
  height: 322px;
  background: rgba(163, 51, 255, 0.3);
  filter: blur(80px);
  border-radius: 50%;
  position: absolute;
  bottom: -10em;
  right: -10rem;
}
.blur-circle-r {
  width: 372px;
  height: 372px;
  background: rgba(51, 51, 255, 0.3);
  filter: blur(80px);
  border-radius: 50%;
  position: absolute;
  bottom: -10em;
  right: -5em;
}
.bg-index{
  z-index: -1;
  
}
.top-radius{
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}
.element-index{
  z-index: 1;
}
.flow-hide {
    overflow-y: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
}
.flow-hide-x {
    overflow-x: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
}
.flow-hide::-webkit-scrollbar { /* WebKit */
    width: 0;
    height: 0;
}
.box-left{
    position: absolute;
    width: 50%;
    height: 20px;
    background-color: #010738;
    bottom: -10px;
    left: 0;

}
.box-right{
    position: absolute;
    width: 50%;
    height: 20px;
    background-color: #010738;
    bottom: -10px;
    right: 0;

}
.content-container{
    width: 90%;
    margin: 0 auto;
    margin-top: 1rem;
    padding-bottom: 10px;
    
    
}
.grado{
  background: linear-gradient(91.02deg, #FAC744 8.95%, #8B14EF 58.82%, #3333FF 107.66%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-weight: 700;
  font-size: 28px;
  line-height: 36px;
}

/* Sportrex Eco  */
.cardContainer{
  position:relative;
  
}
.img-hover{
  position: absolute;
  bottom: 0;
  left: -1.5rem;
  top:0.5rem;
  right: 0;
  color: #fff;
  background:#0E1648;
  transform: translateY(102%);
  transition: transform 0.3s ease-in-out;
  max-height: 100%;
  height: 15rem;
  padding-top:0.5rem;
  width:14rem;
  display:none;
  
}
.m-img-hover{
  position: absolute;
  bottom: 0;
  left: -1.5rem;
  top:0.5rem;
  right: 0;
  color: #fff;
  background:#0E1648;
  transform: translateY(102%);
  transition: transform 0.3s ease-in-out;
  max-height: 100%;
  height: 13rem;
  padding-top:0.5rem;
  width:12rem;
  display:none;
}
.img-hover-text{
  background-color: #0E1648;
  width: 255px;
  height: 255px;
  padding: 1rem;
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  visibility: hidden;
}
.img-hover-text:hover{
  visibility: visible;
  transition: 0.5s;
}
.sport-V{
  border-radius:3.5rem;
}
.cardContainer:hover .img-hover {
  transform: translateY(4%);
  transform: translateX(0%);
  display:block;
}
.krmNah{
  background-color: #3333FF !important ;
}
.eWjCzc{
  background-color: #3333FF !important ;
  color: white !important;
  
}
video{
  min-width:100% !important;
  min-height: 100% !important;
}

.hero-height{
  height:36rem;
}
.gradient-text{
  background: linear-gradient(91.02deg, #FAC744 8.95%, #B257FF 58.82%, #344AFF 107.66%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  fill: transparent;
}
.mtc{
  border-radius: 30px 0px 0px 0px;
}
.tokenM{
border-radius: 0px 30px 0px 0px;
}

.border-token{
  border-radius:1rem 1rem 0 0 ;
}

/* $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ BUTTON STYLES$$$$$$$$$$$$$$$$$$$$$$$ */
.btn-presale{
  display: flex;
  position: relative;
}
.holder-presale{
  color: #3333FF;
  border: #FAC744;
  width: 150px;
  height: 45px;
  border:2px solid #FAC744;
}
.presale{
  color:#0E1648;
  background: #fac744;
  outline: none;
  width: 150px;
  height: auto;
  padding: 0.7rem 1.5rem;
  position: absolute;
  top: 7px;
  left: -7px;
  border: none;
}

.presale:hover{
  position: absolute;
  top:0;
  left: 0;
  transition: 0.5s;
}

.btn-airdrop{
  display: flex;
  position: relative;
  width: fit-content;
}

.holder-airdrop{
  color: #fff;
  border: #3333FF;
  width: 150px;
  height: 45px;
  border:2px solid #3333FF;
}

.airdrop{
  color: white;
  background: #3333FF;
  outline: none;
  width: 150px;
  height: auto;
  padding: 0.7rem 1.5rem;
  position: absolute;
  top: 7px;
  left: -7px;
  border: none;
}
.airdrop:hover{
   position: absolute;
    top:0;
    left: 0;
    transition: 0.5s;
}
.airdrop:active{
    position: absolute;
    top:0;
    left: 0;
    transition: 0.5s;
}

.btn-influencer{
  display: flex;
 position: relative;
 width: fit-content;
}
.holder-influencer{
    color: #fff;
    border: #3333FF;
    width: 250px;
    height: 45px;
    border:2px solid #3333FF;
}
.influencer{
    color: white;
    background: #3333FF;
    outline: none;
    width: 250px;
    height: auto;
     padding: 0.7rem 1.5rem;
    position: absolute;
    top: 7px;
    left: -7px;
    border: none;
}
.influencer:hover{
   position: absolute;
    top:0;
    left: 0;
  
    transition: 0.5s;
}
.influencer:active{
   position: absolute;
    top:0;
    left: 0;
  
    transition: 0.5s;
}



/* ##################END OF BUTTONS############################################ */

/* $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ */
.eco-box{
    background: linear-gradient(91.02deg, #FAC744 8.95%, #8B14EF 58.82%, #3333FF 107.66%);
    width: 270px;
    height: 270px;
    border-radius: 10px;
    padding: 5px;
    position: relative;
}
.min-box{
    width: 100%;
    height: 100%;
    background-color: #0F174D;
    margin: auto;
    position: relative;
}
.overlay-container{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #0F174D;
    padding: 1rem;
  transform: translateY(110%);
  color: white;
    font-size: 1.1rem;
}
 .min-box:hover .overlay-container{
    transform: translateY(0%);
}
 .min-box:active .overlay-container{
    transform: translateY(0%);
}
 .min-box:focus .overlay-container{
    transform: translateY(0%);
}







/* $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ */

@media (max-width: 900px)  {
  .hero-height {
    height:auto;
  }
}
@media (min-width: 1445px)  {
  .sticky-nav {
    left:0;
    right: 0;
  }
}
@media (max-width: 600px)  {
  .hero-height {
    height:auto;
  }
  
  .hero-h{
    height:85vh;
    margin-left: -1rem;
  }
  .m-story{
    color:white;
    font-size:14px;
    margin-bottom: 1rem;
    line-height: 27px;
  }
  .m-story-feature{
     font-size:14px;
    margin-bottom: 8px;
    color:black;
  }
  .m-title{
 background: linear-gradient(91.02deg, #FAC744 8.95%, #B257FF 58.82%, #344AFF 107.66%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
fill: transparent;
font-size: 1.375rem;
line-height: 27.9px;
  margin-bottom :8px !important;
  
  }
  .m-getBtn{
    font-size: 12px;
     padding:1rem 2rem;
  }
  .m-ctaBtn{
    font-size: 14px;
    font-weight: bold;
     padding:1rem 4rem;
       font-family: "BiomeSemibold", sans-serif;
     
  }
  .m-preBtn{
         font-size: 12px;
         padding:1rem 2rem;
  }
  .sport-V{
    border-radius:2rem !important;
  }
  .krmNah{
  background-color: #3333FF !important ;
}
.eWjCzc{
  background-color: #3333FF !important ;
  color: white !important;
  
}
.mobile-card{
  margin-left: -2rem;
}

/* $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ BUTTON STYLES$$$$$$$$$$$$$$$$$$$$$$$ */
.btn-presale{
  display: flex;
 position: relative;
}
.holder-presale{
    color: #3333FF;
    border: #FAC744;
    width: 120px;
    height: 40px;
    border:2px solid #FAC744;
}
.presale{
    color:#0E1648;
    background: #fac744;
    outline: none;
    width: 120px;
    height: auto;
    padding: 0.7rem 1.5rem;
    font-size: 14px;
    position: absolute;
    top: 7px;
    left: -7px;
    border: none;


}
.presale:hover{
   position: absolute;
    top:0;
    left: 0;
  
    transition: 0.5s;
}

.btn-airdrop{
  display: flex;
 position: relative;
 width: fit-content;
}
.holder-airdrop{
    color: #fff;
    border: #3333FF;
    width: 140px;
    height: 40px;
    border:2px solid #3333FF;
}
.airdrop{
    color: white;
    background: #3333FF;
    outline: none;
    width: 140px;
    font-size: 14px;
    height: auto;
     padding: 0.7rem 1.5rem;
    position: absolute;
    top: 7px;
    left: -7px;
    border: none;
}
.airdrop:hover{
   position: absolute;
    top:0;
    left: 0;
  
    transition: 0.5s;
}
.airdrop:active{
   position: absolute;
    top:0;
    left: 0;
  
    transition: 0.5s;
}

.btn-influencer{
  display: flex;
 position: relative;
 width: fit-content;
}
.holder-influencer{
    color: #fff;
    border: #3333FF;
    width: 250px;
    height: 45px;
    border:2px solid #3333FF;
}
.influencer{
    color: white;
    background: #3333FF;
    outline: none;
    width: 250px;
    height: auto;
     padding: 0.7rem 1.5rem;
    position: absolute;
    top: 7px;
    left: -7px;
    border: none;
}
.influencer:hover{
   position: absolute;
    top:0;
    left: 0;
  
    transition: 0.5s;
}
.influencer:active{
   position: absolute;
    top:0;
    left: 0;
  
    transition: 0.5s;
}



/* ##################END OF BUTTONS############################################ */


/* $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ */
.eco-box{
    background: linear-gradient(91.02deg, #FAC744 8.95%, #8B14EF 58.82%, #3333FF 107.66%);
    width: 230px;
    height: 230px;
    border-radius: 10px;
    padding: 5px;
    position: relative;
}
.min-box{
    width: 100%;
    height: 100%;
    background-color: #0F174D;
    margin: auto;
    position: relative;
}
.overlay-container{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #0F174D;
    padding: 1rem;
  transform: translateY(110%);
  color: white;
    font-size: 14px;
}
 .min-box:hover .overlay-container{
    transform: translateY(0%);
}
 .min-box:active .overlay-container{
    transform: translateY(0%);
}
 .min-box:focus .overlay-container{
    transform: translateY(0%);
}

}



/* COMMON */
.flex-center {
  @apply flex items-center justify-center;
}

.smooth {
  @apply transition-all duration-300 ease-linear;
}


/* RESETS */

/* react-elastic-carousel */
.rec-slider-container, .hHJTFD {
  @apply m-0 !important;
}

.rec-arrow {
  display: none;
}

.rec-carousel-item, .rec-item-wrapper {
  @apply min-w-[310px];
}

@media screen and (max-width: 345px) {
  .rec-carousel-item, .rec-item-wrapper {
    min-width: calc(100vw - 80px);
  }
}
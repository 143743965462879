.blur-list-right{
    background: rgba(51, 51, 255, 0.5);
filter: blur(165px);
position: absolute;
z-index: 1;
top: -120px;
/* left: 0; */
right: 0;
width: 400px;
height: 400px;
}
.toper{
    z-index: 5;
}
.blur-list-left{
    background: rgba(51, 51, 255, 0.5);
filter: blur(165px);
position: absolute;
z-index: 1;
top: 0;
left: 0;
/* right: 0; */
width: 400px;
height: 400px;
}
.z-star{
    z-index: 5;
}
@media screen and (max-width: 767px) {
    .blur-list-left{
    background: rgba(51, 51, 255, 0.7);
filter: blur(117px);
position: absolute;
z-index: 1;
top: 0;
left: 0;
right: 0;
width: 331px;
height: 331px;
}
}

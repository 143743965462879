.swiper {
    width: 100%;
    height: 386px;
}
  
  .swiper-slide {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  
  .parallax-bg {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    width: 200%;
    height: 100%;
    -webkit-background-size: contain;
    background-size: contain;
    background-position: top;
    object-fit: contain;
    background-repeat: no-repeat;
  }

.swiper-pagination-bullet {
    background-color:  #ABABAB;
    width: 20px;
    height: 4px;
    border-radius: 8px;
}

.swiper-pagination-bullet-active {
    background-color:  #3333FF;
    width: 30px;
}
.outer {
    @apply relative min-w-full;
}
.container {
    border: 1px solid rgba(255, 255, 255, 0.3);
    clip-path: polygon(20% 0%,10% 0%,0% 15%,0% 100%,100% 100%,100.00% 0.00%);
    @apply rounded-lg min-w-full h-[287px] p-4 relative cursor-pointer;
}
.front {
    @apply pt-14 px-4 flex items-center flex-col z-10 h-full ;
}
.title {
    @apply mt-4 text-white md:text-[20px] text-start font-semibold;
}
.back {
    @apply absolute top-4 left-4 bottom-4 right-4 z-20 text-white py-4 px-6;
}
.front, .back {
    clip-path: polygon(12% 0%,10% 0%,0% 12%,0% 100%,100% 100%,100.00% 0.00%);
    background-color: #101648;
    @apply rounded-lg;
}
.desc {
    @apply leading-7;
}
.blue {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 0px 25px #101648;
    background: linear-gradient(135deg, #3333FF 50%,  transparent 50%);
    @apply absolute top-0 left-0 h-3 w-3 z-40;
}
.container {
    box-sizing: border-box;
    @apply border-[2px] transition-all duration-500 ease-linear;
}
.button {
    box-sizing: content-box;
    @apply font-semibold text-xs sm:text-base flex items-center justify-center -translate-x-2 translate-y-2 transition-all duration-200 ease-linear px-4;
}

.container:hover .button {
    @apply -translate-x-0 -translate-y-0;
}

.container:hover {
    @apply border-opacity-0;
}